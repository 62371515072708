<template>
	<tr>
		<td class="d-flex flex-nowrap">
			<v-avatar class="mr-2 flex-shrink-1" size="48">
				<v-img alt="Avatar" :src="item.user.imgURL" :aspect-ratio="1 / 1" />
			</v-avatar>
			<div class="d-flex justify-space-between flex-grow-1">
				<router-link class="text-decoration-none red--text my-auto" :to="{ name: 'User', params: { id: item.user.id } }">
					{{ item.user.fullname }}
				</router-link>
				<v-btn color="info" icon class="mx-1 my-auto" @click="openChat(item)">
					<v-icon left>
						mdi-android-messages
					</v-icon>
					<span class="d-sr-only">{{ $t('chats.chat') }}</span>
				</v-btn>
			</div>
		</td>
		<td class="text-center">
			<v-chip
				small
				:loading="loadingDocument"
				:disabled="loadingDocument"
				:ripple="false"
				:color="item.documents.cv.uploaded ? 'success' : 'error'"
				class="mx-1"
				@click="item.documents.cv.uploaded ? callDownloadDocument(item.documents.cv.parentID) : callNoDocument()"
			>
				<v-icon left>mdi-cloud-download</v-icon>
				{{ $t('documents.cv') }}
			</v-chip>
			<v-chip
				small
				:loading="loadingDocument"
				:disabled="loadingDocument"
				:ripple="false"
				:color="item.documents.motivation.uploaded ? 'success' : 'error'"
				class="mx-1"
				@click="item.documents.motivation.uploaded ? callDownloadDocument(item.documents.motivation.parentID) : callNoDocument()"
			>
				<v-icon left>mdi-cloud-download</v-icon>
				{{ $t('documents.motivationLetter') }}
			</v-chip>
			<v-chip
				small
				:loading="loadingDocument"
				:disabled="loadingDocument"
				:ripple="false"
				:color="item.documents.records.uploaded ? 'success' : 'error'"
				class="mx-1"
				@click="item.documents.records.uploaded ? callDownloadDocument(item.documents.records.parentID) : callNoDocument()"
			>
				<v-icon left>mdi-cloud-download</v-icon>
				{{ $t('documents.records') }}
			</v-chip>
		</td>
		<td class="text-center">
			<template v-if="item.status.prioritized">
				<v-btn
					color="success"
					outlined
					rounded
					:loading="loadingAction"
					:disabled="loadingAction"
					class="mx-1"
					@click="callPrioritizeApplication(item, '0')"
				>
					<v-icon left>
						mdi-close
					</v-icon>
					{{ $t('applications.reject') }}
				</v-btn>
			</template>
			<template v-else-if="item.status.normal">
				<v-btn color="success" rounded class="mx-1" :loading="loadingAction" :disabled="loadingAction" @click="callPrioritizeApplication(item, '1')">
					<v-icon left>mdi-check</v-icon> {{ $t('applications.prioritize') }}
				</v-btn>
				<v-btn color="error" rounded class="mx-1" :loading="loadingAction" :disabled="loadingAction" @click="callDiscardApplication(item, '1')">
					<v-icon left>mdi-close</v-icon> {{ $t('applications.discard') }}
				</v-btn>
			</template>
			<template v-else-if="item.status.discarded">
				<v-btn
					color="error"
					outlined
					rounded
					class="mx-1"
					:loading="loadingAction"
					:disabled="loadingAction"
					@click="callDiscardApplication(item, '0')"
				>
					<v-icon left>mdi-check</v-icon> {{ $t('applications.accept') }}
				</v-btn>
			</template>
		</td>
	</tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'OfferApplicationsUserItemDesktop',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		},
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			loadingAction: false,
			loadingDocument: false
		}
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer'
		})
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
		},
		callDownloadDocument(parentID) {
			this.loadingDocument = true
			this.downloadDocumentApplication({ parentID }).then(() => {
				this.loadingDocument = false
			})
		},
		callNoDocument() {
			this.noDocument()
		},
		callPrioritizeApplication(application, actionType) {
			this.loadingAction = true
			this.prioritizeApplication({ application, actionType }).then(() => {
				this.loadingAction = false
			})
		},
		callDiscardApplication(application, actionType) {
			this.loadingAction = true
			this.discardApplication({ application, actionType }).then(() => {
				this.loadingAction = false
			})
		},
		openChat(application) {
			if (application.chat) {
				this.$router.push({ name: 'MessagesChat', params: { id: application.chat.id } })
			} else {
				this.$router.push({ name: 'MessagesChatOfferNew', params: { application, offer: this.offer } })
			}
		},
		...mapActions('applications', ['prioritizeApplication', 'discardApplication']),
		...mapActions('documents', ['downloadDocumentApplication', 'noDocument'])
	}
}
</script>
